import React from "react"
import { graphql } from "gatsby"

import SForm from "../components/Forms/SForm"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SPage = ({ data }) => (
  <Layout>
    <SEO title="Шпонированные панели" />
    <SForm data={data} />
  </Layout>
)

export const query = graphql`
  query SPageQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 240, placeholder: BLURRED, formats: PNG)
          }
        }
      }
    }
  }
`

export default SPage
