import React, { useEffect, useState } from "react"
import { RadioGroup } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function RadioGroupCards({
  options,
  checkedItem,
  onChange = () => {},
}) {
  const cols = options.length

  return (
    <RadioGroup
      value={checkedItem}
      by="id"
      onChange={onChange}
      className="mt-2"
    >
      <RadioGroup.Label className="sr-only">Выберите тип</RadioGroup.Label>
      <div
        className={`grid gap-1 sm:gap-2 ${
          cols === 2 ? "grid-cols-2" : "grid-cols-3"
        }`}
      >
        {options.map(option => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ active, checked }) =>
              classNames(
                "cursor-pointer focus:outline-none",
                checked
                  ? "border-transparent bg-indigo-600 text-white hover:bg-indigo-700"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                "flex w-full items-center justify-center rounded-md border py-0 px-3 text-center text-sm font-medium uppercase sm:flex-1 sm:py-2"
              )
            }
          >
            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
