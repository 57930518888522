import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

function RadioGroupSides({
  data,
  items,
  top,
  left,
  right,
  bottom,
  setTop = () => {},
  setLeft = () => {},
  setRight = () => {},
  setBottom = () => {},
}) {
  return (
    <div className="mx-auto w-full py-4">
      <div className="">
        <div className="flex justify-center pb-2">
          <div />

          <div
            onClick={() => setTop(!top)}
            className={`flex w-64 shrink-0 justify-center rounded-full shadow hover:scale-110 ${
              top ? "bg-indigo-100 text-indigo-500" : "bg-white text-gray-400"
            }`}
          >
            <CheckIcon className="h-10 w-10" />
          </div>

          <div />
        </div>

        <div className="flex justify-center space-x-2">
          <div
            onClick={() => setLeft(!left)}
            className={`flex shrink-0 items-center rounded-full shadow hover:scale-110 ${
              left ? "bg-indigo-100 text-indigo-500" : "bg-white text-gray-400"
            }`}
          >
            <CheckIcon className="h-10 w-10" />
          </div>

          <div className="rounded-lg bg-white px-5 py-4 shadow-md">
            {data.allFile.edges.map((item, index) => {
              if (items[0].name === item.node.name) {
                return (
                  <div key={index} className="flex justify-center">
                    <GatsbyImage
                      image={item.node.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  </div>
                )
              }
            })}
          </div>

          <div
            onClick={() => setRight(!right)}
            className={`flex shrink-0 items-center rounded-full shadow hover:scale-110 ${
              right ? "bg-indigo-100 text-indigo-500" : "bg-white text-gray-400"
            }`}
          >
            <CheckIcon className="h-10 w-10" />
          </div>
        </div>
        <div className="flex justify-center pt-2">
          <div />

          <div
            onClick={() => setBottom(!bottom)}
            className={`flex w-64 shrink-0 justify-center rounded-full shadow hover:scale-110 ${
              bottom
                ? "bg-indigo-100 text-indigo-500"
                : "bg-white text-gray-400"
            }`}
          >
            <CheckIcon className="h-10 w-10" />
          </div>

          <div />
        </div>
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  )
}

export default RadioGroupSides
